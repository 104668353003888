<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotEquipment' }">设备管理</el-breadcrumb-item>
          <el-breadcrumb-item>历史记录</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="search">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="上报时间">
            <el-date-picker
              size="small"
              v-model="formInline.finishTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-list" style="margin:0 0 100px;">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="waterworks" label="水厂">
            <template slot-scope="scope">
              {{ scope.row.waterworks?scope.row.waterworks:'-' }}
            </template>
          </el-table-column>
          <el-table-column prop="position" label="泵房编号">
            <template slot-scope="scope">
              {{ scope.row.position?scope.row.position:'-' }}
            </template>
          </el-table-column>
          <el-table-column prop="equipmentName" label="设备名称"></el-table-column>
          <el-table-column prop="eid" label="设备号"></el-table-column>
          <el-table-column prop="flowInstantaneous" label="瞬时流量（m³/h）">
            <template slot-scope="scope">
              {{ scope.row.content.flowInstantaneous }}
            </template>
          </el-table-column>
          <el-table-column prop="fluidVelocity" label="瞬时流速（m/s）">
            <template slot-scope="scope">
              {{ scope.row.content.fluidVelocity }}
            </template>
          </el-table-column>
          <el-table-column prop="positiveCumulativeFlow" label="正累积流量（m³）">
            <template slot-scope="scope">
              {{ scope.row.content.positiveCumulativeFlow }}
            </template>
          </el-table-column>
          <el-table-column prop="negativeCumulativeFlow" label="负累积流量（m³）">
            <template slot-scope="scope">
              {{ scope.row.content.negativeCumulativeFlow }}
            </template>
          </el-table-column>
          <el-table-column prop="reportTime" label="上报时间">
            <template slot-scope="scope">
              {{ scope.row.reportTime ? dayjs(scope.row.reportTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
      <div class="bottom-btn">
        <el-button type="primary" size="small" @click="$router.push('/iotEquipment')">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {
        finishTime:[]
      },
      row: {},
      tableData: [
        {
          questionId: 1637035485469,
          result: '正常',
          waterMeterNo: 'LK-5649',
          staffName: ['张威'],
          reportTime: 1637660902000,
          position: '已采集',
        },
        {
          questionId: 1637035485469,
          result: '正常',
          waterMeterNo: 'TP-6548',
          staffName: ['李丹'],
          reportTime: 1637466731000,
          position: '已采集',
        },
        {
          questionId: 1637035485469,
          result: '异常',
          waterMeterNo: 'QW-3456',
          staffName: ['刘娜'],
          reportTime: 1637303655000,
          position: '已采集',
        },
        {
          questionId: 1637035485469,
          result: '正常',
          waterMeterNo: 'LF-2468',
          staffName: ['王涛'],
          reportTime: 1637195713000,
          position: '已采集',
        }
      ],
      sheshi: [],
      staffId: 0,
      roleId: 0
    }
  },
  methods: {
    dayjs,
    onSubmit() {
      this.page = 1
      sessionStorage.setItem('currentPage', 1)
      this.loadTableData()
    },
    // 巡查转指派
    turnPatrol(row) {
      this.$router.push({ name: 'direcPatrolTurnAdd', params: {row: row}})
    },
    details(row) {
      this.$router.push({ name: 'patrolReportDetail', params: {row: row}})
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadTableData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadTableData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    loadTableData() {
      let dates = [] 
      if(this.formInline.finishTime){
        this.formInline.finishTime.forEach((i)=>{
          var date = new Date(i)
          dates.push(date.getTime()) 
        })
      }
      
      this.loading = true
      this.$ajax.post('reportRecordQuery', {
        pageNum: this.page,
        pageSize: this.size,
        eid: this.row.eid?this.row.eid:null,
        deviceTypeCode: this.row.deviceTypeCode?this.row.deviceTypeCode:null,
        subEid: this.row.subEid?this.row.subEid:null,
        startTime: dates?dates[0]:null,
        endTime: dates?dates[1]:null,
      }).then(res => {
        this.loading = false
        this.tableData = res.data
        this.total = res.total
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
        this.$router.push('/iotEquipment')
    }
    this.row = this.$route.params.row
    console.log(this.row)
    this.getLastPage()
    this.getSize()
    this.loadTableData()
    this.staffId = sessionStorage.getItem('id')
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    sessionStorage.removeItem('currentPage')
    sessionStorage.removeItem('currentSize')
  }
}
</script>

<style lang="less" scoped>
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.btn {
  float: right;
}
.search {
  padding: 10px;
}
</style>